.testimonials-grid {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin: 10px 5%;
}

.testimonials-arrow {
    margin: 0px 10px;
    width: 3%;
    height: 100px;
    align-self: center;
    cursor: pointer;
}

.right-arrow {
    transform: rotate(180deg);
}

.testimonials {
    width: 80%;
    background: #FBF1E7;
    border-radius: 20px;
    padding: 50px;
    min-height: 200px;
}

.testimonials-name {
    font-family: 'Lilita One', 'Atma';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
}

.testimonials-star {
    width: 25px;
    margin: 5px 3px;
}

.testimonials-content {
    margin: 15px;
    font-family: 'Josefin Sans';
    line-height: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}

@media screen and (max-width:1024px) {
    .testimonials-arrow {
        height: 80px;
    }
    
    .testimonials {
        width: 80%;
        border-radius: 15px;
        padding: 40px;
        min-height: 200px;
    }
    
    .testimonials-name {
        font-size: 24px;
    }
    
    .testimonials-star {
        width: 20px;
        margin: 3px 2px;
    }
    
    .testimonials-content {
        margin: 15px;
        font-size: 16px;
    }
}


@media screen and (max-width:720px) {
    .testimonials-arrow {
        height: 60px;
    }
    
    .testimonials {
        border-radius: 10px;
        padding: 30px;
        min-height: 200px;
    }
    
    .testimonials-name {
        font-size: 20px;
    }
    
    .testimonials-star {
        width: 15px;
        margin: 3px 2px;
    }
    
    .testimonials-content {
        margin: 15px;
        font-size: 16px;
    }
}