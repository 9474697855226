.our-blog-grid{
    display: grid;
    grid-template-columns: 5% auto 5%;
    padding: 0px 2%;
}

.our-blog-arrow {
    width: 30px;
    margin: auto;
    cursor: pointer;
}

.our-blog-list {
    width: 100%;
    display: flex;
    flex-direction: row;
}


.home-blog-grid {
    width: 33%;
    background: #FFFFFF;
    padding: 20px;
    cursor: pointer;
    margin: 1%;
}

.home-blog-image {
    width: 90%;
    margin: auto;
    display: grid;
    max-height: 30vh;
    object-fit: contain;
}

.home-blog-title {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #E2C1A3;
    margin: 5px;
    text-align: center;
}

.home-blog-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    margin: 30px;
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
    max-height: 80px;
}

.home-blog-read-more {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    color: #E2C1A3;
    margin: 30px;
    text-decoration: underline;
}

@media screen and (max-width: 1024px) {
    .home-blog-title {
        font-size: 20px;
        line-height: 30px;
    }
    
    .home-blog-content {
        font-size: 18px;
        margin: 20px;
        line-height: 20px;
        max-height: 80px;
    }
    
    .home-blog-read-more {
        font-size: 16px;
        line-height: 15px;
        margin: 20px;
    }
} 

@media screen and (max-width: 720px) {
    .our-blog-arrow {
        width: 15px;
    }
    
    .home-blog-grid {
        width: 48%;
    }

    .our-blog-list-item-3 {
        display: none;
    }

    .home-blog-title {
        font-size: 18px;
        line-height: 25px;
        margin: 2px;
    }
    
    .home-blog-content {
        font-size: 16px;
        margin: 10px;
        line-height: 20px;
        max-height: 80px;
    }
    
    .home-blog-read-more {
        font-size: 14px;
        line-height: 15px;
        margin: 10px;
    }
} 