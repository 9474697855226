.popup-grid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
    display: grid;
}

.popup {
    width: 400px;
    aspect-ratio: 6/1;
    padding: 80px;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: #FFF7EF;
    border: 3px solid #e4c5ab;
    border-radius: 10px;
    margin: auto;

    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #000000;
    white-space:break-spaces;
    word-break: break-word;
}

@media screen and (max-width:1024px) {   
    .popup {
        font-size: 20px;
        padding: 60px;
    }
}

@media screen and (max-width:720px) {   
    .popup {
        width: 330px;
        font-size: 18px;
        padding: 50px;
    }
}

@media screen and (max-width:480px) {   
    .popup {
        width: 250px;
        font-size: 16px;
        padding: 40px;
    }
}