
.features-grid {
    display: grid;
    justify-items: center;
}

.features-row-1,
.features-row-2 {
    width: 90%;
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
}

.features-row-1 {
    justify-content: space-between;
}

.features-row-2 {
    justify-content: space-evenly;
}

.features {
    width: 250px;
    display: grid;
    justify-items: center;
}

.features-image {
    width: 150px;
    height: 150px;
    border-radius: 200px;
}

.features-desc {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.features-desc-2,
.features-desc-3 {
    width: 230px;
}

.features-desc-1,
.features-desc-5 {
    width: 250px;
}


.features-desc-4 {
    width: 250px;
}

@media screen and (max-width:1024px) { 

    .features-row-1,
    .features-row-2 {
        margin: 20px 0px;
    }

    .features {
        width: 230px;
    }

    .features-image {
        width: 140px;
        height: 140px;
    }

    .features-desc {
        font-size: 23px;
    }

    .features-desc-2,
    .features-desc-3 {
        width: 210px;
    }

    .features-desc-1,
    .features-desc-5 {
        width: 230px;
    }


    .features-desc-4 {
        width: 240px;
    }    
}


@media screen and (max-width:720px) {

    .features-row-1,
    .features-row-2 {
        margin: 20px 0px;
    }

    .features {
        width: 165px;
    }

    .features-image {
        width: 100px;
        height: 100px;
    }

    .features-desc {
        font-size: 20px;
    }

    .features-desc-2,
    .features-desc-3 {
        width: 120px;
    }

    .features-desc-1,
    .features-desc-5 {
        width: 130px;
    }

    .features-desc-4 {
        width: 130px;
    }    
}


@media screen and (max-width:480px) { 
    .features-row-1,
    .features-row-2 {
        display: grid;
        margin: 20px 0px;
        justify-content: center;
    }

    .features {
        width: 180px;
        margin: 20px 0px;
    }
}