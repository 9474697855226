.header-sticky-grid {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    z-index: 10;
}

.app-header-grid {
    width: 96%;
    margin: 0px 2%;
    height: 50px;
    display: grid;
    grid-template-columns: 50px auto 60%;
}
  
.app-header-logo {
    width: 43px;
    height: 43px;
    border-radius: 43px;
    margin: auto;
    cursor: pointer;
}
  
.app-header-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    /* line-height: 37px; */
    color: #BC8A5B;
    margin: auto 10px;
    cursor: pointer;
}

.app-header-nav-grid {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: end;
    flex-direction: row;
}

.app-header-nav {
    margin: auto 0px;
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    /* line-height: 25px; */
    color: #BC8A5B;
    cursor: pointer;
    justify-content: right;
    margin-left: 8%;
}

.mobile-view {
    display: none;
}


.header-reserve {
    border: 2px solid #BC8A5B;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

    height: 33px;
    line-height: 33px;
    font-family: 'Open Sans';
    font-weight: 600;
    text-align: center;
    color: #BC8A5B;
}


@media only screen and (max-width: 1340px) {
    .app-header-nav {
        margin-left: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .app-header-grid, 
    .app-header-nav-grid {
        height: 35px;
    }

    .app-header-grid {
        grid-template-columns: 35px auto 60%;
    }

    .app-header-logo {
        width: 30px;
        height: 30px;
    }
    
    .app-header-name {
        font-size: 20px;
    }

    .app-header-nav {
        margin-left: 25px;
        font-size: 13px;
    }

    .header-reserve {
        height: 25px;
        line-height: 25px;
    }
  }

  
@media only screen and (max-width: 720px) {
    .app-header-grid, 
    .app-header-nav-grid {
        height: 35px;
    }
    
    .app-header-grid {
        grid-template-columns: 28px 205px auto;
    }

    .app-header-logo {
        width: 28px;
        height: 28px;
    }
    
    .app-header-name {
        font-size: 20px;
        margin: auto 5px;
    }

    .app-header-nav {
        font-size: 15px;
        margin: auto 0px;
    }

    .mobile-view {
        display: flex;
        justify-content: end;
    }

    .web-view {
        display: none;
    }

    .mobile-nav-icon {
        width: 25px;
        height: 20px;
        margin: auto 8px;
        opacity: 70%;
    }

    .mobile-nav-grid-background.active {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
    }

    .mobile-nav-grid {
        display: none;
    }

    .mobile-nav-grid.active {
        z-index: 1;
        display: flex;
        flex-direction: column;
        background-color: rgba(255,247,239,0.9);
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        height: 150px;
    }

    
    .header-reserve {
        font-size: 11px;
        padding: 0px 5px;
        border-radius: 5px;
        overflow: hidden;
        height: 20px;
        line-height: 20px;
    }
  }