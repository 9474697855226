html {
    scroll-behavior: smooth;
}

.colored-background {
    background-color: #FFF7EF;
}

.section-grid {
    padding-bottom: 10vh;
}

.section-title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 57px;
    color: #000000;
    padding: 3%;
}

.text-justify {
    text-align: justify;
}

.text-center {
    text-align: center;
}

@media screen and (max-width:1024px) {
    .section-title {
        font-size: 35px;
    }
}


@media screen and (max-width:720px) {
    .section-title {
        font-size: 30px;
    }
}


@media screen and (max-width:480px) {
    .section-title {
        font-size: 25px;
    }
}