.location-grid{
    width: 100%;
    display: grid;
    justify-items: center;
}

.location-map {
    width: 60%;
    aspect-ratio: 3/2;
    border: none;
    display: grid;
    justify-self: center;
}

@media screen and (max-width:1024px) {
    .location-map {
        width: 70%;
    }
}