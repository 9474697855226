.services-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.service-grid {
    display: flex;
    flex-direction: column;
}

.service-image {
    width: 400px;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.service-name {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    color: #000000;
    margin: 15px;
}

.service-desc {
    margin: 0px auto;
    font-family: 'Cormorant';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    width: 350px;
}

.service-learn-more {
    margin: 10px auto;
    width: 350px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #E2C1A3;
    text-decoration: underline;
}

@media screen and (max-width:1024px) {
    .service-image {
        width: 300px;
    }

    .service-name {
        font-size: 25px;
        margin: 15px;
    }
    
    .service-desc {
        font-size: 18px;
        width: 300px;
    }
    
    .service-learn-more {
        width: 300px;
        font-size: 18px;
    }
    
}


@media screen and (max-width:720px) {
    .service-image {
        width: 200px;
    }

    .service-name {
        font-size: 20px;
        margin: 15px;
    }
    
    .service-desc {
        font-size: 15px;
        width: 200px;
    }
    
    .service-learn-more {
        width: 200px;
        font-size: 15px;
    }
    
}


@media screen and (max-width: 480px) {
    .service-image {
        width: 150px;
    }

    .service-name {
        font-size: 18px;
        margin: 15px;
    }

    .service-desc {
        font-size: 14px;
        width: 150px;
    }

    .service-learn-more {
        width: 150px;
        font-size: 12px;
    }

}