.footer-grid {
    padding: 0px 5%;
    background: #BC8A5B;
    width: calc(100% - 10%);
    height: 50px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 14px; */
    color: #FFFFFF;
    display: grid;
    grid-template-columns: auto auto;
}

.footer-span {
    margin: auto 0px;
}

.footer-sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.social-media-icon-grid {
    justify-self: end;
    height: 30px;
    /* text-align: center; */
    margin: auto 0px;
}

.social-media-icon {
    height: 30px;
    margin-left: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .footer-grid {
        height: 40px;
        font-size: 12px;
    }

    .social-media-icon-grid {
        height: 25px;
    }

    .social-media-icon {
        height: 25px;
        margin-left: 20px;
    }

}

@media screen and (max-width: 720px) {
    .footer-grid {
        height: 35px;
        font-size: 10px;
    }
    
}


@media screen and (max-width:480px) {
    .footer-grid {
        height: 30px;
        font-size: 8px;
    }
    
    .social-media-icon-grid {
        height: 20px;
    }
    
    .social-media-icon {
        height: 20px;
        margin-left: 20px;
    }
}