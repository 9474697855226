.reserve-form-grid {
    display: block;
    margin: 20vh auto;
    width: 70%;
    padding: 3% 5%;
    border-radius: 20px;
}

.hr-lines {
    margin: 50px 0px;
    width: 100%;
    display: grid;
    grid-template-columns: auto 205px auto;
    grid-gap: 2%;
    font-size: 35px;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 1000;
    text-align: center;
}

.reserve-form-issues-title{
    margin-top: 50px;
    margin-bottom: 20px;
    /* width: 100%; */
    /* display: grid; */
    /* grid-template-columns: auto 205px auto; */
    /* grid-gap: 2%; */
    font-size: 35px;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 1000;
    /* text-align: center; */
}

.hr-lines:before,
.hr-lines:after {
    content:" ";
    display: block;
    height: 2px;
    background: gray;
    align-self: center;
}

.reserve-form-note {
    margin: 15px 0px;
    line-height: 30px;
}

.reserve-form-field {
    display: grid;
    margin: 5px;
    height: 140px;
    grid-template-rows: 30% 30% 30%;
}
.reserve-form-row {
    display: grid;
}

.reserve-form-two-in-a-row {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
}

.reserve-form-three-in-a-row {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
}

.reserve-form-label,
.reserve-form-input-text,
.reserve-form-submit,
.reserve-form-input-radio,
.reserve-form-pet-number-grid,
.reserve-form-note{
    width: 100%;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #000000;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.reserve-form-label {
    font-weight: 800;
    margin: 10px 0px;
}

.reserve-form-input-text.calendar-icon {
    background-image: url('../images/calendar.svg');
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: 9px 9px;
    padding-left: 40px;
    width: calc(100% - 43px - 10px);
}

.reserve-form-input-text {
    background: white;
    border: 1px solid gray;
    padding: 10px;
    width: calc(100% - 20px);
}

.reserve-form-input-radio {
    padding: 10px 0px;
}

.reserve-form-input-radio label {
    padding: 0px 10px;
}

.reserve-form-submit-grid {
    width: 100%;
    display: grid;
    justify-items: center;
    margin: 20px 0px;
}

.reserve-form-submit {
    display: block;
    margin: 10px 0px;
    justify-self: center;
    text-align: center;
    border-top: none;
    border-right: none;
    border-bottom: 3px solid #9e8a76;
    border-left: 3px solid #9e8a76;
    background-color: #deb691;
    border-radius: 10px;
    width: 250px;
    line-height: 40px;
    color: white;
    cursor: pointer;
}

.reserve-form-alter-pet-count {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #1166d6;
    align-items: center;
    text-decoration: underline;
    margin: 10px;
}

input.required,
input.invalid {
    border-color: red;
}

.required-text,
.invalid-text {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    margin: 10px;
    color: red;
}

.reserve-form-add-icon,
.reserve-form-minus-icon {
    width: 30px;
    height: 30px;
    margin: 0px 10px;
}

.reserve-form-pet-number-row {
    height: 50px;
    display: grid;
    grid-template-columns: 50px 50px 50px;
    margin: 10px;
    align-items: center;
    justify-items: center;
}

.reserve-form-pet-number-grid {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid #e4c5ab;
}

.react-datepicker__input-container.required .calendar-icon {
    border-color: red;
}

.reserve-form-aggrement-grid {
    font-size: 20px;
}

@media screen and (max-width:1024px) {
    .hr-lines {
        grid-template-columns: auto 180px auto;
        font-size: 29px;
    }

    .reserve-form-issues-title{
        font-size: 29px;
    }

    .reserve-form-two-in-a-row {
        grid-template-columns: 47% 47%;
        grid-gap: 6%;
    }

    .reserve-form-label,
    .reserve-form-input-text,
    .reserve-form-submit,
    .reserve-form-input-radio,
    .reserve-form-pet-number-grid,
    .reserve-form-note {
        font-size: 18px;
    }

    .reserve-form-input-text.calendar-icon {
        background-size: 18px;
        background-position: 10px 13px;
        padding-left: 35px;
        width: calc(100% - 35px - 10px);
    }
    
    .required-text,
    .invalid-text {
        font-size: 15px;
        margin: 8px;
    }

    .reserve-form-aggrement-grid {
        font-size: 16px;
    }
}

@media screen and (max-width:720px) {
    .reserve-form-grid {
        /* padding: 3% 1%; */
        border-radius: 20px;
        width: 80%;
    }

    .reserve-form-two-in-a-row {
        grid-template-columns: 100%;
        grid-gap: 0px;
    }
}

@media screen and (max-width:480px) {
    .hr-lines {
        grid-template-columns: auto 145px auto;
        grid-gap: 2%;
        font-size: 23px;
    }

    .reserve-form-issues-title{
        font-size: 23px;
    }
    
    .reserve-form-field {
        margin: 10px 5px;
    }

    .reserve-form-label,
    .reserve-form-input-text,
    .reserve-form-submit,
    .reserve-form-input-radio,
    .reserve-form-pet-number-grid,
    .reserve-form-note {
        font-size: 15px;
    }
    
    .reserve-form-input-radio label {
        padding: 0px 5px;
    }

    .required-text,
    .invalid-text {
        font-size: 13px;
        margin: 8px;
    }

    .reserve-form-add-icon,
    .reserve-form-minus-icon {
        width: 25px;
        height: 25px;
    }

    .reserve-form-pet-number-row {
        grid-template-columns: 40px 40px 40px;
        margin: 5px;
    }

    .reserve-form-pet-number-grid {
        width: 35px;
        height: 35px;
    }
    
    .reserve-form-aggrement-grid {
        font-size: 13px;
    }
}