.contact-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 660px 230px;
    grid-gap: 40px;
    justify-content: center;
    margin: auto;
}

.contact-form {
    width: 580px;
    background-color: white;
    border-radius: 20px;
    padding: 40px 60px;
    display: grid;

}

.contact-form-row {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
}

.contact-form-row .contact-form-label,
.contact-form-row .contact-form-input  {
    width: 90%;
}

.contact-form-label,
.contact-form-input,
.contact-form-submit {
    width: 95%;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
}

.contact-form-label {
    width: calc(95% - 5px);
    margin: 5px;
}

.contact-form-input {
    padding: 5px 10px;
    margin-bottom: 20px;
}

.contact-form-submit {
    margin: 20px 0px;
    justify-self: center;
    text-align: center;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #9e8a76;
    border-left: 1px solid #9e8a76;
    background-color: #deb691;
    border-radius: 10px;
    width: 150px;
    line-height: 40px;
    color: white;
    cursor: pointer;
}

.contact-details {
    border-left: 2px solid #B1B1B1;
    padding: 20px;
    padding-right: 0px;
    margin: 20px;
    margin-right: 0px;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
    height: 250px;
}

@media screen and (max-width:1024px) {
    .contact-grid {
        grid-template-columns: 60% 33%;
        grid-gap: 3%;
    }
    
    .contact-form {
        width: calc(95% - 8%);
        border-radius: 20px;
        padding: 20px 8%;
    }
    
    /* .contact-form-row {
        display: fl;
    } */
    
    .contact-form-row .contact-form-label,
    .contact-form-row .contact-form-input  {
        width: 90%;
    }
    
    .contact-form-label,
    .contact-form-input,
    .contact-form-submit {
        width: 95%;
        font-size: 18px;
    }
    
    .contact-form-submit {
        margin: 20px 0px;
        border-radius: 8px;
        width: 150px;
        line-height: 40px;
    }
    
    .contact-details {
        font-size: 16px;
        height: 250px;
    }
}

@media screen and (max-width:720px) {
    .contact-grid {
        grid-template-columns: 100%;
    }
    
    .contact-form {
        width: 80%;
        border-radius: 20px;
        padding: 20px 5%;
        margin: auto;
    }
    
    .contact-form-row {
        display: block;
    }
    
    .contact-form-row .contact-form-label,
    .contact-form-row .contact-form-input  {
        width: 85%;
    }
    
    .contact-form-label,
    .contact-form-input,
    .contact-form-submit {
        width: 85%;
        font-size: 18px;
    }
    
    .contact-form-submit {
        margin: 20px 0px;
        border-radius: 8px;
        width: 150px;
        line-height: 40px;
    }
    
    .contact-details {
        font-size: 16px;
        height: 250px;
        padding: 10px;
    }
}

@media screen and (max-width:480px) {
    .contact-grid {
        grid-template-columns: 100%;
    }

    .contact-form {
        width: 70%;
        border-radius: 20px;
        padding: 20px 10%;
        margin: auto;
    }
    
    .contact-form-label,
    .contact-form-input,
    .contact-form-submit {
        width: 85%;
        font-size: 16px;
    }

    .contact-details {
        height: 200px;
    }
}