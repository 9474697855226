.cover-grid {
    width: 100%;
    aspect-ratio: 2/1;
    background-image: url('../images/cover.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right top;
}

.cover-text-grid {
    position: relative;
    width: 38%;
    top: 15%;
    left: 62%;
    display: flex;
    flex-direction: column;
    display: grid;
    justify-content: center;
}

.cover-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    color: #FFFFFF;
    -webkit-text-stroke:  2px #6C5C4A;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: block;
    width: 380px;
}

.cover-slogan {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
    -webkit-text-stroke: 2px #7B7676;
    display: block;
    width: 380px;
}

.cover-reserve-grid {
    position: relative;
    top: 20%;
    left: 62%;
    width: 38%;
    display: grid;
    justify-content: center;
}

.cover-date-picker-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 280px;
    border: 2px solid #7B7676;
    background: white;
    border-radius: 10px;
    cursor: pointer;
}

.from-date-picker,
.to-date-picker {
    width: 35%;
    padding: 2% 7%;
}

.from-date-picker {
    border-right: 1px solid #7B7676;
}

.cover-date-label {
    color: #6C5C4A;
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 16px;
}

.cover-date-value {
    color: #6C5C4A;
    font-family: 'Playfair Display';
    font-size: 20px;
    font-weight: 800;
}

.cover-date-picker-grid .react-datepicker__input-container,
.cover-date-picker-grid .react-datepicker-wrapper {
    width: 125px;
}

.cover-reserve {
    background: #BC8A5B;
    border: 1px solid #FFEBD3;
    border-radius: 10px;
    margin: 10px 0px;
    width: 100%;
    cursor: pointer;

    height: 35px;
    line-height: 35px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
}

@media screen and (max-width:1024px) {
    .cover-text-grid {
        width: 40%;
        left: 60%;
    }

    .cover-title {
        font-size: 28px;
        -webkit-text-stroke:  2px #6C5C4A;
        width: 280px;
    }
    
    .cover-slogan {
        font-size: 20px;
        -webkit-text-stroke: 1px #7B7676;
        width: 280px;
    }

    .cover-reserve-grid {
        width: 40%;
        left: 60%;
    }

    .from-date-picker,
    .to-date-picker {
        width: 41%;
        padding: 2% 4%;
    }

    .cover-date-label {
        font-size: 14px;
    }

    .cover-date-value {
        font-size: 16px;
    }
    
    .cover-date-picker-grid{
        width: 190px;
        border-radius: 8px;
    }

    .cover-date-picker-grid .react-datepicker__input-container,
    .cover-date-picker-grid .react-datepicker-wrapper {
        width: 85px;
    }

    .cover-reserve {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        border-radius: 8px;
    }
}


@media screen and (max-width:720px) {
    .cover-grid {
        background-size: 110%;
        aspect-ratio: 11/6;
    }


    .cover-title {
        font-size: 19px;
        -webkit-text-stroke:  1px #6C5C4A;
        width: 190px;
    }
    
    .cover-slogan {
        font-size: 15px;
        -webkit-text-stroke: 1px #7B7676;
        width: 190px;
    }

    .cover-date-picker-grid {
        width: 140px;
        border: 1.5px solid #7B7676;
        border-radius: 5px;
    }

    .cover-date-label {
        font-size: 10px;
    }

    .cover-date-value{
        font-size: 12px;
    }
    
    .cover-date-picker-grid .react-datepicker__input-container,
    .cover-date-picker-grid .react-datepicker-wrapper {
        width: 60px;
    }

    .cover-reserve {
        font-size: 9px;
        height: 20px;
        line-height: 20px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 480px) {
    .cover-grid {
        background-size: 125%;
        aspect-ratio: 40/25;
    }

    .cover-text-grid {
        width: 47%;
        top: 20%;
        left: 53%;
    }

    .cover-title {
        font-size: 16px;
        width: 160px;
    }

    .cover-slogan {
        font-size: 12px;
        width: 160px;
    }

    .cover-reserve-grid {
        width: 47%;
        top: 25%;
        left: 53%;
    }
    
    .from-date-picker,
    .to-date-picker {
        width: 45%;
        padding: 2% 2%;
    }

    .cover-date-label {
        font-size: 10px;
    }

    .cover-date-value {
        font-size: 9px;
    }
    
    .cover-date-picker-grid{
        width: 100px;
    }
    
    .cover-date-picker-grid .react-datepicker__input-container,
    .cover-date-picker-grid .react-datepicker-wrapper {
        width: 45px;
    }

    .cover-reserve {
        font-size: 9px;
        height: 20px;
        line-height: 20px;
        border-radius: 5px;
    }
}