.blog-grid {
    width: 100%;
    display: grid;
    margin-bottom: 50vh;
}

.blog-title-grid {
    width: 100%;
    margin: 30px 0px;
    display: grid;
}

.blog-title {
    width: 80%;
    justify-self: center; 
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    margin: 15px 0px;
}

.blog-date {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #D6C6B8;
}

.blog-img {
    width: 90%;
    justify-self: center; 
    max-height: 80vh;
    object-fit: contain;
    margin-top: 50px;
}

.blog-para {
    width: 80%;
    justify-self: center; 
    text-align: justify;
    font-size: 20px;
    font-family: 'Open Sans';
}

@media screen and (max-width: 1024px) {
    .blog-img {
        margin-top: 35px;
    }

    .blog-title-grid {
        margin: 20px 0px;
    }
    
    .blog-title {
        font-size: 35px;
        margin: 10px 0px;
    }

    .blog-date {
        font-size: 15px;
    }

    .blog-para {
        font-size: 18px;
    }
}

@media screen and (max-width: 720px) {
    .blog-title-grid {
        margin: 15px 0px;
    }
    
    .blog-title {
        font-size: 30px;
        margin: 8px 0px;
    }

    .blog-date {
        font-size: 12px;
    }

    .blog-para {
        font-size: 15px;
    }
}

/* @media screen and (max-width: 480px) {
    .blog-title-grid {
        margin: 14px 0px;
    }
    
    .blog-title {
        font-size: 25px;
        margin: 8px 0px;
    }

    .blog-date {
        font-size: 10px;
    }

    .blog-para {
        font-size: 12px;
    }
} */